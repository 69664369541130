<script setup lang="ts">
import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { toRefs } from 'vue'

const accountStore = useCorporativeAccount()
const { isUsingCorporateAccount, selectedAccount } = storeToRefs(accountStore)

const props = defineProps<{ id?: number; img?: string; text?: string }>()
const { id, img, text } = toRefs(props)
</script>

<template>
  <div
    class="flex items-center px-4 py-3 hover:opacity-50"
    :class="`${isUsingCorporateAccount ? 'bg-corporate' : 'bg-white'} ${
      selectedAccount?.id == id ? 'border-l-4 border-grey-4' : ''
    }`"
    v-bind="$attrs"
  >
    <img
      v-if="img"
      :src="props.img"
      class="mr-4 h-[2.4rem] w-[2.4rem] rounded-xl bg-white object-cover"
    />

    <span
      v-if="text"
      class="mr-1 w-4/5 truncate text-sm"
      :class="isUsingCorporateAccount ? 'text-white' : 'text-grey-2'"
      >{{ props.text }}
    </span>
    <slot class="w-1/5"> </slot>
  </div>
</template>
