import { createApp } from 'vue'
import App from './App.vue'
import KeyCloakService from './security/KeycloakService'
import '@mdi/font/css/materialdesignicons.min.css'
import './styles/global.css'
import useStore from './store/auth'
import { router } from './router'
import CountryFlag from 'vue-country-flag-next'
import { vMaska } from 'maska/vue'
import { i18n } from './plugins/i18n'
import { pinia } from './plugins/pinia'
import './plugins/vee-validate'
import './plugins/zod'
import '@/components/index'
import { registerGlobalComponents } from '@/components/index'
import { VueTelInput } from 'vue-tel-input'
import { KeycloakTokenParsed } from 'keycloak-js'
import { vPermission } from './directives/vPermission'
import {
  ToastPlugin,
  vueToastificationOptions,
} from './plugins/vue-toastification'
import { vuetify } from './plugins/vuetify'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

function setup(user?: KeycloakTokenParsed | object) {
  const app = createApp(App)
  if (user) {
    app.use(ToastPlugin, vueToastificationOptions)
    app.use(vuetify)
    app.component('CountryFlag', CountryFlag)
    app.component('VueTelInput', VueTelInput)
    app.directive('maska', vMaska)
    app.component('QuillEditor', QuillEditor)
    app.directive('permission', vPermission)
    registerGlobalComponents(app)
  }
  app.use(pinia)
  app.use(router)
  app.use(i18n)
  app.mount('#app')
  const store = useStore()
  store.setUser(user)
}

if (import.meta.env.VITE_TEST == 'true') {
  setup({})
} else {
  KeyCloakService.CallLogin(({ user }) => {
    if (user === undefined) {
      setup({})
      router.push('error')
    } else {
      setup(user)
    }
  })
}
