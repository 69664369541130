<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  CircleStencil,
  Cropper,
  CropperResult,
  RectangleStencil,
} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const cropperVisible = ref(true)
const props = defineProps<{
  open: boolean
  image?: string
  type?: string
}>()

const cropperRef = ref<InstanceType<typeof Cropper> | null>(null)
const zoomValue = ref(1) // Valor inicial do zoom
const resultRef = ref<CropperResult>()
const localImage = ref()
const isAdjusting = ref(false)

const emit = defineEmits(['close', 'save', 'update:dialog', 'update:image'])

const image = computed({
  get() {
    return props.image
  },
  set(value) {
    localImage.value = value
    emit('update:image', value)
  },
})

function handleZoom(event: Event) {
  const value = parseFloat((event.target as HTMLInputElement).value)
  zoomValue.value = value
  isAdjusting.value = true // Ativa exibição durante ajuste
}

function stopAdjusting() {
  isAdjusting.value = false // Oculta o valor ao terminar ajuste
}

function reset() {
  zoomValue.value = 1
  resultRef.value = undefined

  // Desmontar e remontar o componente
  cropperVisible.value = false
  setTimeout(() => {
    cropperVisible.value = true
  }, 0)
}

function handleChange(result: CropperResult) {
  resultRef.value = result
}
/*
function handleSave() {
  const dataUrl = resultRef.value?.canvas?.toDataURL('image/jpeg', 1.0)
  emit('save', dataUrl)
}
  */

function handleSave() {
  if (!resultRef.value?.canvas) {
    console.error('Canvas result is undefined or invalid.')
    emit('save', null) // Emit um valor padrão em caso de erro
    return
  }
  const dataUrl = resultRef.value.canvas.toDataURL('image/jpeg', 1.0)
  console.log('Image Data URL:', dataUrl) // Para depuração
  emit('save', dataUrl)
}

const hintPosition = computed(() => {
  // Normaliza a posição do slider em porcentagem (entre 0% e 100%)
  const range = 1.5 - 0.5 // Max - Min
  return ((zoomValue.value - 0.5) / range) * 100
})

// Monitorar o zoomValue e aplicar o zoom corretamente na imagem usando a API do cropper
watch(zoomValue, (newValue) => {
  if (cropperRef.value) {
    cropperRef.value.zoom(newValue) // Usando o método `zoom` da API do cropper
  }
})
</script>

<template>
  <Dialog
    id="crop-image-dialog"
    :open="open"
    class="!w-[41rem] !max-w-xl"
    :title="$t('contacts.adjustProfilePicture')"
    @close="$emit('close')"
  >
    <div
      class="mx-2 flex w-full flex-col items-center justify-center overflow-hidden"
    >
      <p class="w-full text-left text-sm font-bold text-gray-700">
        {{ $t('contacts.adjustProfilePictureHint') }}
      </p>
    </div>
    <div
      class="mx-2 flex !h-[40vh] w-full flex-col items-center justify-center overflow-hidden"
    >
      <Cropper
        v-if="cropperVisible"
        ref="cropperRef"
        :stencil-component="
          type === 'circle' ? CircleStencil : RectangleStencil
        "
        class="h-full w-full bg-white"
        :src="image"
        :stencil-props="{
          handlers: {},
          lines: true,
          movable: true,
          resizable: false,
          aspectRatio: 1,
        }"
        :stencil-size="{
          width: 200,
          height: 200,
        }"
        image-restriction="fit-area"
        :canvas="{
          minHeight: 0,
          minWidth: 0,
          maxHeight: 200,
          maxWidth: 200,
        }"
        @change="handleChange"
      />
    </div>
    <div class="flex w-full flex-col gap-4">
      <div class="relative flex items-center space-x-4">
        <p class="pr-4 text-sm font-bold text-gray-700">
          {{ $t('general.zoom') }}
        </p>
        <div class="relative w-full">
          <!-- Slider -->
          <input
            id="zoom-slider"
            type="range"
            min="0.5"
            max="1.5"
            step="0.1"
            :value="zoomValue"
            class="zoom-slider"
            @input="handleZoom"
            @change="stopAdjusting"
          />
          <!-- Hint -->
          <span
            v-if="isAdjusting"
            class="absolute -top-8 rounded bg-white px-2 py-1 text-sm font-medium text-gray-600 shadow"
            :style="{ left: `${hintPosition}%`, transform: 'translateX(-50%)' }"
          >
            {{ zoomValue.toFixed(1) }}x
          </span>
        </div>
      </div>

      <div class="flex justify-center gap-4">
        <Button id="cancel-button" class="bg-grey-3" @click="reset">
          {{ $t('general.reset') }}
        </Button>
        <Button id="action-button" class="bg-green" @click="handleSave">
          {{ $t('general.continue') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<style scoped>
/* Estilo do slider geral */
input.zoom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%; /* Largura total */
  height: 8px; /* Altura do track */
  background: #e6e6e6; /* Cor do track */
  border-radius: 5px; /* Track arredondado */
  outline: none; /* Remove borda */
  cursor: pointer;
}

/* Estilo do thumb (a bolinha do ponteiro) */
input.zoom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Tamanho da bolinha */
  height: 20px;
  background: #8dc63f; /* Cor do thumb */
  border-radius: 50%; /* Deixa redondo */
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); /* Sombra leve */
}

input.zoom-slider::-moz-range-thumb,
input.zoom-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #8dc63f;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
</style>
