<script setup lang="ts">
import EditDataSection from '@/components/EditDataSection.vue'
import FormInput from '@/components/FormInput.vue'
import { useCorporativeAccount } from '@/store/corporative'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { toRefs, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { z } from 'zod'

import { useLoading } from '@/composables/useLoading'
import { MessageSchema } from '@/plugins/i18n'
import AddressProvider from '@/providers/AddressProvider.vue'
import { useAddress } from '@/store/address'
import {
  Address,
  Country,
  EditLabelHandlerParams,
  addressFormSchema,
} from '@/types/address'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { usePostalCodeMask } from '@/composables/usePostalCodeMask'
import { usePromise } from '@/composables/usePromise'
import { emailFormSchema } from '@/types/email'
import { phoneFormSchema } from '@/types/phone'
import { toTypedSchema } from '@vee-validate/zod'
import { vMaska } from 'maska/vue'
import { onMounted } from 'vue'
import { corporateSchema } from '../types/corporative'

const route = useRoute()
const router = useRouter()
const { id } = route.params
const accountStore = useCorporativeAccount()

const {
  getCorporate,
  updateCorporativeAccount,
  updateCorporativeAccountLogo,
  getCorporates,
  removeLogoCompany,
} = accountStore
const { loading: loadingAccount, corporate } = storeToRefs(accountStore)

const addressStore = useAddress()
const { loading: loadingAddress, countries } = toRefs(addressStore)

const { countryMask, maskOptions } = usePostalCodeMask()

const maskOptionsCNPJ = computed(() => ({
  mask: '##.###.###/####-##',
}))
const validationSchema = corporateSchema.merge(
  z.object({
    addresses: z
      .array(
        addressFormSchema
          .refine(
            (val: Partial<Address>) => {
              if (!val.postalCode) return true
              const result = countries.value
                ?.find((c: Country) => c.countryCode === val.countryCode)
                ?.zodRule.safeParse(val.postalCode).success
              return result
            },
            { message: 'validation.postalCode', path: ['postalCode'] }
          )

          .or(
            z.preprocess(
              (val) =>
                !(val as { countryCode: string }).countryCode ? undefined : val,
              z.undefined()
            )
          )
      )
      .optional(),
    phones: z
      .array(
        z.preprocess(
          (val) =>
            !(val as { countryCode: string }).countryCode ? undefined : val,
          phoneFormSchema.nullish()
        )
      )
      .transform((phones) =>
        phones.map((p, i) => ({
          ...p,
          label: p?.label || t(`corporate.edit.sections.phones.defaultLabel`),
          main: i == 0,
        }))
      )
      .optional(),
    emails: z
      .array(
        emailFormSchema.or(
          z.preprocess(
            (val) => (!(val as { email: string }).email ? undefined : val),
            z.undefined()
          )
        )
      )
      .transform((emails) =>
        emails.map((e, i) => ({
          ...e,
          label: e?.label || t(`corporate.edit.sections.emails.defaultLabel`),
          main: i == 0,
        }))
      )
      .optional(),
  })
)

const { handleSubmit, setValues, setFieldValue, meta, values } = useForm({
  validationSchema: toTypedSchema(validationSchema),
})

const { t } = useI18n<MessageSchema>()
const promiseHandler = usePromise(t)

const toast = useToast()

const selectedDocType = 'CNPJ'

async function handleRemoveLogo() {
  await promiseHandler(removeLogoCompany(Number(id)), [
    'corporate.edit.logoUpdateSuccess',
    'corporate.edit.logoUpdateError',
  ])
  fetchData()
}

const onSubmit = handleSubmit(
  async (values) => {
    useLoading(
      async () => {
        const filteredValues = {
          ...values,
          emails: values.emails?.filter((e) => e && e?.email),
          phones: values.phones?.filter((p) => p && p?.number),
          addresses: values.addresses
            ? (
                values.addresses?.filter(
                  (a) => a && Object.keys(a).includes('countryCode')
                ) as Address[]
              ).map((c, i) => ({
                ...c,
                label:
                  c?.label ||
                  t(`corporate.edit.sections.addresses.defaultLabel`),
                main: i == 0,
              }))
            : [],
        }
        delete filteredValues.logo
        await promiseHandler(updateCorporativeAccount(filteredValues), [
          'corporate.edit.success',
          'corporate.edit.error',
        ])
        if (values.logo instanceof File) {
          await promiseHandler(updateCorporativeAccountLogo(values.logo), [
            'corporate.edit.logoUpdateSuccess',
            'corporate.edit.logoUpdateError',
          ])
        }

        getCorporates()
        router.push({ name: 'detail-corporative-account', params: { id } })
      },
      { store: 'account' }
    )
  },
  ({ values, errors, results }) => {
    console.log(values)
    console.log(errors)
    console.log(results)
    toast.error(t('validation.formErrors'))
  }
)

async function fetchData() {
  useLoading(async () => {
    await getCorporate()

    setValues({
      ...(corporate.value as z.infer<typeof validationSchema>),
      emails: corporate.value?.emails?.length ? corporate.value?.emails : [{}],
      phones: corporate.value?.phones?.length ? corporate.value?.phones : [{}],
      addresses: corporate.value?.addresses?.length
        ? corporate.value?.addresses
        : [{}],
    })
  })
}

onMounted(() => {
  fetchData()
})
watch(
  () => route.params.id,
  () => {
    fetchData()
  }
)
</script>

<template>
  <Page>
    <template #page-title>
      <PageHeader :title="$t('corporate.edit.title')" :back-button="false" />
    </template>

    <div class="grid h-full grid-cols-3 gap-2">
      <div
        class="scroll col-span-1 mt-5 flex h-[97%] flex-col justify-start overflow-auto pl-5 pr-2"
      >
        <h3 class="pb-4 text-xl font-bold">
          {{ $t('corporate.edit.profilePicuture') }}
        </h3>
        <div class="mb-4 flex items-center">
          <FileInput
            :file-preview="values.logo"
            class="mb-6"
            @change="(v: File) => setFieldValue('logo', v)"
            @delete="handleRemoveLogo"
          />
        </div>
        <h3 class="text-xl font-bold">
          {{ $t('corporate.edit.sections.general.title') }}
        </h3>
        <FormInput
          id="edit-corporative-account-name"
          name="name"
          :label="$t('corporate.edit.sections.general.fields.name')"
          :placeholder="$t('corporate.edit.sections.general.fields.name')"
        />
        <FormInput
          id="edit-corporative-account-trading-name"
          name="tradingName"
          :label="$t('corporate.edit.sections.general.fields.tradingName')"
          :placeholder="
            $t('corporate.edit.sections.general.fields.tradingName')
          "
        />
        <div class="flex gap-2">
          <Input
            id="edit-corporative-account-doc-type"
            v-model="selectedDocType"
            :label="t('corporate.create.fields[3]')"
            disabled="true"
          />
          <FormInput
            id="edit-corporative-account-document"
            v-maska="maskOptionsCNPJ"
            name="document"
            :label="t('corporate.create.fields[4]')"
            :disabled="true"
          />
        </div>
        <FormInput
          id="edit-corporative-account-about"
          :label="$t('corporate.edit.sections.general.fields.about')"
          :placeholder="$t('corporate.edit.sections.general.fields.about')"
          name="about"
          type="textarea"
        />
        <br />
        <EditDataSection
          v-slot="{ idx }"
          :title="$t('corporate.edit.sections.emails.title')"
          :action-label="$t(`corporate.edit.sections.emails.actionLabel`)"
          :default-label="$t(`corporate.edit.sections.emails.defaultLabel`)"
          section="emails"
          :default-item-value="{
            label: $t(`corporate.edit.sections.emails.defaultLabel`),
            main: false,
          }"
          @edit-label="
            (args: EditLabelHandlerParams) =>
              values?.emails &&
              values?.emails[args.id] &&
              setFieldValue(`emails.${args.id}`, {
                ...(values.emails[args.id] as Address),
                label: args.label,
              })
          "
        >
          <FormInput
            :id="`edit-corporative-account-emails-${idx}-input`"
            :name="`emails[${idx}].email`"
          />
        </EditDataSection>
        <br />

        <EditDataSection
          v-slot="{ idx }"
          :default-item-value="{
            label: $t(`corporate.edit.sections.phones.defaultLabel`),
            main: false,
          }"
          :title="$t('corporate.edit.sections.phones.title')"
          :action-label="$t(`corporate.edit.sections.phones.actionLabel`)"
          :default-label="$t(`corporate.edit.sections.phones.defaultLabel`)"
          section="phones"
          @edit-label="
            (args: EditLabelHandlerParams) =>
              values?.phones &&
              values?.phones[args.id] &&
              setFieldValue(`phones.${args.id}`, {
                ...(values.phones[args.id] as Address),
                label: args.label,
              })
          "
        >
          <AddressProvider>
            <div class="flex w-full gap-4">
              <FormInput
                :id="`edit-corporative-account-phones-${idx}-input`"
                :name="`phones[${idx}].number`"
                type="phone"
                @country-code="
                  (v: string) => {
                    if (!v) return
                    setFieldValue(`phones.${idx}`, {
                      ...(values.phones ? (values.phones[idx] as object) : {}),
                      countryCode: v,
                    })
                  }
                "
              />
            </div>
          </AddressProvider>
        </EditDataSection>
      </div>

      <div
        class="scroll col-span-1 mt-5 flex h-[97%] flex-col justify-start overflow-y-auto pl-5 pr-2"
      >
        <EditDataSection
          v-slot="{ idx, field }"
          :default-item-value="{
            label: $t(`corporate.edit.sections.addresses.defaultLabel`),
            main: false,
          }"
          :title="$t('corporate.edit.sections.addresses.title')"
          :action-label="$t(`corporate.edit.sections.addresses.actionLabel`)"
          :default-label="$t(`corporate.edit.sections.addresses.defaultLabel`)"
          section="addresses"
          @edit-label="
            (args: EditLabelHandlerParams) =>
              values?.addresses &&
              values?.addresses[args.id] &&
              setFieldValue(`addresses.${args.id}`, {
                ...(values.addresses[args.id] as Address),
                label: args.label,
              })
          "
        >
          <div class="mb-4 flex flex-col gap-1">
            <AddressProvider
              v-slot="{
                cities,
                states,
                selectedCountry,
                setSelectedCountry,
                setSelectedState,
              }"
              :country="(field?.value as Address)?.countryCode"
              :state="(field?.value as Address)?.stateCode"
              :on-update-country="
                (country: Country) => {
                  countryMask.regex = country.postalCodeRegex
                  countryMask.format = country.postalCodeFormat
                }
              "
            >
              <FormInput
                id="edit-corporative-account-postal-code"
                v-maska="maskOptions"
                :name="`addresses[${idx}].postalCode`"
                :label="$t('corporate.edit.sections.addresses.fields.areaCode')"
              />
              <FormInput
                id="edit-corporative-account-country-code"
                :name="`addresses[${idx}].countryCode`"
                :label="$t('corporate.edit.sections.addresses.fields.country')"
                :type="'select'"
                :items="countries"
                label-field="name"
                value-field="countryCode"
                :loading="loadingAddress"
                @change="
                  (country: Country) => {
                    if (!country) setFieldValue(`addresses.${idx}`, undefined)
                    setSelectedCountry(country)
                  }
                "
              />

              <div class="flex w-full gap-2">
                <FormInput
                  id="edit-corporative-account-state-code"
                  :name="`addresses[${idx}].stateCode`"
                  :label="$t('corporate.edit.sections.addresses.fields.state')"
                  :rules="selectedCountry ? 'required' : ''"
                  :type="'select'"
                  :items="states"
                  label-field="name"
                  value-field="stateCode"
                  :loading="loadingAddress"
                  @change="setSelectedState"
                />

                <FormInput
                  id="edit-corporative-account-city-id"
                  :name="`addresses[${idx}].cityId`"
                  :label="$t('corporate.edit.sections.addresses.fields.city')"
                  :rules="selectedCountry ? 'required' : ''"
                  :type="'select'"
                  :items="cities"
                  label-field="name"
                  value-field="id"
                  :loading="loadingAddress"
                />
              </div>

              <FormInput
                v-for="(line, index) in selectedCountry?.addressSize"
                :id="`edit-corporative-account-address-line-${index}`"
                :key="line"
                :label="
                  line == 1
                    ? $t('corporate.edit.sections.addresses.fields.address')
                    : ''
                "
                :name="`addresses[${idx}].line${line + 0}`"
              />
            </AddressProvider>
          </div>
        </EditDataSection>
      </div>
    </div>

    <template #footer>
      <Button class="bg-grey-3" @click="router.back()">{{
        t('general.cancel')
      }}</Button>
      <Button
        id="save-edit-corporative-account"
        type="button"
        :disabled="!meta.valid"
        :loading="loadingAccount"
        @click="onSubmit"
        >{{ $t('general.save') }}</Button
      >
    </template>
  </Page>
</template>

<style scoped>
@-moz-document url-prefix() {
  br {
    margin-top: 0.62rem;
    margin-bottom: 0.62rem;
  }
}
</style>
