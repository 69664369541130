import { computed, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useCurrency = () => {
  const { locale } = useI18n({ useScope: 'global' })

  const currencyNames = new Intl.DisplayNames([locale.value], {
    type: 'currency',
  })
  const currencyList = [
    {
      isoCode: 'br',
      currencySymbol: 'R$',
      currencyName: currencyNames.of('BRL'),
      currencyCode: 'BRL',
    },
    {
      isoCode: 'us',
      currencySymbol: '$',
      currencyName: currencyNames.of('USD'),
      currencyCode: 'USD',
    },
    {
      isoCode: 'ar',
      currencySymbol: '$',
      currencyName: currencyNames.of('ARS'),
      currencyCode: 'ARS',
    },
    {
      isoCode: 'cl',
      currencySymbol: '$',
      currencyName: currencyNames.of('CLP'),
      currencyCode: 'CLP',
    },
    {
      isoCode: 'co',
      currencySymbol: '$',
      currencyName: currencyNames.of('COP'),
      currencyCode: 'COP',
    },
    {
      isoCode: 'mx',
      currencySymbol: '$',
      currencyName: currencyNames.of('MXN'),
      currencyCode: 'MXN',
    },
    {
      isoCode: 'pe',
      currencySymbol: 'S/.',
      currencyName: currencyNames.of('PEN'),
      currencyCode: 'PEN',
    },
  ]

  const selectedCurrency: Ref<(typeof currencyList)[number]> = ref(
    currencyList.find((c) =>
      locale.value.toLowerCase().includes(c.isoCode.toLowerCase())
    ) || currencyList[0]
  )

  const placeholder = computed(() =>
    new Intl.NumberFormat(selectedCurrency.value.isoCode, {
      style: 'currency',
      currency: selectedCurrency.value.currencyCode,
    }).format(0)
  )

  return { selectedCurrency, currencyList, placeholder }
}
