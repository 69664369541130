<script setup lang="ts">
import UserMenu from './UserMenu.vue'
import TopNav from './TopNav.vue'

import { useLayout } from '@/composables/useLayout'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useCorporativeAccount } from '@/store/corporative'
import emptyStateEventLogo from '@/assets/images/emptyStateEventLogo.svg'
import newLogoEve from '@/assets/images/newLogoEve.svg'

const { isSidebarOpen } = useLayout()

const dynamicClasses = computed(() =>
  isSidebarOpen.value
    ? {
        header: 'h-[8vh]',
        images: 'w-[2.6rem] h-[2.6rem] ml-0',
        tile: 'w-[0.3rem]',
      }
    : {
        header: 'h-[6vh]',
        images: 'w-[2.0rem] h-[2.0rem] ml-0',
        tile: 'w-[4rem]',
      }
)

const accountStore = useCorporativeAccount()
const { isUsingCorporateAccount } = storeToRefs(accountStore)
</script>

<template>
  <header
    class="relative flex w-full items-center justify-between transition-all duration-100"
    :class="`${dynamicClasses.header} ${
      isUsingCorporateAccount ? 'bg-corporate' : 'bg-personal '
    }`"
  >
    <div class="h-full flex-grow">
      <div
        id="logo-container"
        class="relative flex h-full w-full items-center justify-start px-2 transition duration-100"
      >
        <div
          class="absolute left-0 top-0 min-h-full transition-all duration-500 ease-out"
          :class="dynamicClasses.tile"
        >
          &nbsp;
        </div>

        <div
          class="flex h-12 w-12 items-center justify-center rounded-lg bg-white transition-all duration-500"
        >
          <RouterLink
            to="/"
            class="flex h-full w-full items-center justify-center"
          >
            <img
              :src="emptyStateEventLogo"
              alt="Logotipo do evento"
              class="z-20 object-cover transition-all"
              :class="dynamicClasses.images"
            />
          </RouterLink>
        </div>
      </div>
    </div>
    <TopNav />
    <UserMenu />
    <button
      type="button"
      class="mr-4 flex h-12 w-12 items-center justify-center rounded-xl bg-white transition duration-100"
      :class="dynamicClasses.images"
    >
      <img :src="newLogoEve" class="h-6 w-6 rounded-full object-cover" alt="" />
    </button>
  </header>
</template>
