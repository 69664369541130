<script setup lang="ts">
import CountryFlag from 'vue-country-flag-next'

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'
import Input from '../Input.vue'
import { computed, onMounted, watch } from 'vue'
import { useCurrency } from '@/composables/useCurrency'
import { CurrencyValue } from '@/modules/custom-fields/types'

const emit = defineEmits(['currency', 'blur'])

const props = defineProps<{
  value?: unknown
  precision?: number
  error?: boolean
  min?: number
}>()

const { selectedCurrency, currencyList, placeholder } = useCurrency()

const value = computed({
  get: () => props.value,
  set: (newVal: unknown) => {
    if ((newVal as CurrencyValue)?.amount === null)
      return emit('currency', null)
    const t = {
      currency: (newVal as CurrencyValue)?.currency,
      amount:
        (newVal as CurrencyValue)?.amount == '0'
          ? '0.00'
          : String(Number((newVal as CurrencyValue)?.amount)?.toFixed(2)),
    }
    emit('currency', t)
  },
})

const { inputRef, numberValue, setOptions, setValue, formattedValue } =
  useCurrencyInput({
    currency: 'BRL',
    useGrouping: false,
    autoDecimalDigits: true,
    precision: props.precision || 2,
    hideCurrencySymbolOnFocus: false,
    valueRange: {
      min: props.min || undefined,
    },
  })

// ao trocar o tipo de moeda, é necessário mudar a configuração para essa moeda
watch(selectedCurrency, () => {
  setOptions({
    currency: selectedCurrency.value.currencyCode || 'BRL',
    currencyDisplay: 'symbol' as CurrencyDisplay,
    precision: props.precision || 2,
    useGrouping: false,

    autoDecimalDigits: true,
    valueRange: {
      min: props.min || undefined,
    },
  })
  value.value = {
    currency: selectedCurrency.value.currencyCode,
    amount: (value.value as CurrencyValue)?.amount,
  }
})

// reseta caso o valor esteja fora do permitido
watch(
  () => props.min,
  () => {
    if (
      typeof props.min != 'undefined' &&
      numberValue.value &&
      numberValue.value < props.min
    )
      setValue(props.min)
  }
)

// a verificação existe para não setar valor nulo
watch(numberValue, (newVal) => {
  value.value = {
    currency: selectedCurrency.value.currencyCode,
    amount: newVal,
  }
})

onMounted(() => {
  // esse timeout é necessário para que a ref interna da lib seja carregada para depois ser inicializada

  setTimeout(() => {
    if ((props.value as CurrencyValue)?.currency)
      selectedCurrency.value =
        currencyList.find((c) =>
          ((props.value as CurrencyValue)?.currency as string)
            .toLowerCase()
            .includes(c.isoCode.toLowerCase())
        ) || currencyList[0]
    if (
      (props.value as CurrencyValue)?.amount != String(numberValue.value) &&
      typeof (props.value as CurrencyValue)?.amount != 'undefined'
    ) {
      setValue(Number((props.value as CurrencyValue)?.amount))
    }
  }, 500)
})
</script>

<template>
  <div>
    <Listbox v-model="selectedCurrency" as="div" class="relative w-full">
      <ListboxButton
        id="select-currency"
        class="absolute top-[0.5rem] z-10 ml-0 rounded-l-xl border-r-[1px] border-grey-4 p-2 outline-none peer-focus:border-grey-2 peer-focus:bg-blue-100"
      >
        <div
          class="flex h-[1rem] w-[0.8rem] items-center justify-center overflow-hidden"
        >
          <CountryFlag :country="selectedCurrency.isoCode" size="small" />
        </div>
      </ListboxButton>
      <ListboxOptions
        class="absolute top-[2.5rem] z-20 max-h-[10rem] w-[16rem] overflow-y-auto rounded-xl border-[1px] border-grey-4 bg-white outline-none"
      >
        <ListboxOption
          v-for="(currency, idx) in currencyList"
          :key="currency.currencyCode"
          :value="currency"
          :data-value="currency.currencyCode"
          class="flex cursor-pointer items-center gap-2 px-2 py-2 text-sm text-grey-4 hover:bg-grey-4 hover:text-black"
          :class="idx === 1 ? 'border-b-[1px] border-b-grey-4' : ''"
        >
          <div
            class="flex h-[1.2rem] w-[1.2rem] items-center justify-center overflow-hidden rounded-md"
          >
            <CountryFlag :country="currency.isoCode" size="small" />
          </div>
          <p>
            {{ currency.currencySymbol }} -
            {{ currency.currencyName }}
          </p>
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
    <Input
      ref="inputRef"
      :model-value="formattedValue ? String(formattedValue) : ''"
      :error="error"
      :placeholder="placeholder"
      input-classes=" pl-[2.2rem]"
      @blur="(e: FocusEvent) => e.preventDefault()"
      @keydown="
        (e: KeyboardEvent) => {
          if (e.key === '-' && min === 0) e.preventDefault()
        }
      "
    />
  </div>
</template>
