<script setup lang="ts" generic="T">
import greenInfoIcon from '@/assets/icons/green/info.svg'
import { computed, toRefs, ref, Teleport } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import arrowDownIcon from '@/assets/icons/black/arrow_down.svg'
import { useMenuPosition } from '@/composables/useMenuPosition'
import { MessageSchema } from '@/plugins/i18n'
import { useI18n } from 'vue-i18n'
const { t } = useI18n<MessageSchema>()

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    label: string
    required?: boolean
    items: T[] | null
    modelValue?: string | number | undefined
    labelField?: keyof T | string
    valueField?: keyof T | string
    loading?: boolean
    error?: boolean
    emptyState?: boolean
    placeholder?: string
    disabled?: boolean
    tooltip?: string
    description?: string
  }>(),
  {
    required: false,
    labelField: 'label',
    valueField: 'value',
    modelValue: undefined,
    loading: false,
    error: false,
    emptyState: true,
    placeholder: undefined,
    disabled: false,
    tooltip: undefined,
    description: undefined,
  }
)

const emit = defineEmits(['update:modelValue'])
const { required, items } = toRefs(props)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const getItemValue = (item: T): string | number | undefined => {
  return item[props.valueField as keyof T] as string | number | undefined
}

const listboxRef = ref<HTMLElement | null>(null)
const isMenuAbove = ref(false)

const calculateMenuPosition = () => {
  if (listboxRef.value) {
    const { bottom } = listboxRef.value.getBoundingClientRect()
    const windowHeight = window.innerHeight
    const menuHeight = 200
    isMenuAbove.value = bottom + menuHeight > windowHeight
  }
}
const { triggerRect, computedStyles, toggleMenu } = useMenuPosition()

const selectedItemLabel = computed(() => {
  const selectedItem = items.value?.find(
    (item: T) => getItemValue(item) === value.value
  )
  return selectedItem
    ? selectedItem[props.labelField as keyof T]
    : props.placeholder || t('general.pickAnOption')
})
</script>

<template>
  <div class="flex min-w-32 flex-col pt-1">
    <div class="flex gap-2">
      <h6
        v-if="props.label"
        class="mb-2 text-sm font-bold"
        :class="{
          '!text-error': props.error,
          '!text-grey-4': !props.error && props.disabled,
          'w-11/12': tooltip,
        }"
      >
        {{ props.label }} {{ required ? '*' : '' }}
      </h6>
      <div v-if="tooltip" class="relative flex">
        <img
          id="tooltip"
          width="24"
          :src="greenInfoIcon"
          @mouseover="toggleMenu"
          @mouseout="toggleMenu"
        />
        <Teleport to="body">
          <TransitionRoot
            :show="Boolean(triggerRect)"
            enter="transition-opacity duration-150"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              id="tooltip-text"
              :style="computedStyles"
              class="absolute z-50 mt-2 h-max w-max max-w-[30rem] origin-top-left divide-y break-words rounded-md bg-grey-1 p-2 font-light text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {{ tooltip }}
            </div>
          </TransitionRoot>
        </Teleport>
      </div>
    </div>
    <p v-if="description" id="description" class="mb-1 text-xs text-grey-2">
      {{ description }}
    </p>
    <div ref="listboxRef" class="relative flex pt-1">
      <Listbox v-model="value" :disabled="props.loading || props.disabled">
        <div class="relative w-full">
          <ListboxButton
            :id="String($attrs.id)"
            v-slot="{ open }"
            class="sm:text-md relative h-[2.1rem] min-h-[2.1rem] w-full cursor-default rounded-xl border border-grey-4 bg-white py-1 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 disabled:cursor-not-allowed disabled:opacity-50"
            @click.stop="calculateMenuPosition"
          >
            <span
              :class="{ 'text-grey-4': !value }"
              class="text-md block truncate"
            >
              {{ selectedItemLabel }}
            </span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <img
                :src="arrowDownIcon"
                :class="{ 'rotate-180': open }"
                class="transition-transform duration-200"
              />
            </span>
          </ListboxButton>
          <ListboxOptions
            :class="[
              'text-md absolute z-50 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none',
              isMenuAbove ? 'bottom-full mb-1' : 'top-full mt-1',
            ]"
            style="max-height: 25rem; overflow-y: auto"
          >
            <ListboxOption :disabled="required" :value="undefined">
              <li
                :class="[
                  'font-normal',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                  required ? 'opacity-30' : 'opacity-15',
                  required ? '' : 'hover:bg-green',
                ]"
              >
                <span class="text-md block truncate font-normal">
                  {{ placeholder || $t('general.noOptionSelected') }}
                </span>
              </li>
            </ListboxOption>

            <ListboxOption
              v-for="(item, index) in items"
              :key="index"
              :value="item[props.valueField]"
              :data-value="item[props.valueField]"
              as="template"
            >
              <li
                :class="[
                  'relative cursor-pointer select-none py-2 pl-10 pr-4',
                  'hover:bg-green hover:text-white',
                ]"
              >
                <!-- Option text with ellipsis and md text size -->
                <span class="text-md block truncate">
                  {{ item[props.labelField as keyof T] }}
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  </div>
</template>
